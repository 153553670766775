import { getAllStates, getAllStatesExcluding } from "../../States";
// TODO: Create an ENUM for services

const mentalHealthProviders = [
  {
    companyName: "Rula",
    logo: "rula_logo.png",
    borderColor: "black",
    availability: 2,
    virtualLocations: getAllStatesExcluding("New Jersey"),
    inPersonLocations: null,
    insurances: [
      "Aetna",
      "Anthem / Blue Cross Blue Shield",
      "Cigna",
      "Kaiser (by referral)",
      "Optum",
      "Oscar",
      "United Healthcare",
      "and more",
    ],
    price: "$15",
    priceDetail:
      "with insurance, usually around $15 per session, payment is $150/session, and $165 for couples/family therapy, $350 for psychiatry intake, and $150 for psychiatry follow-ups",
    youthAgeRange: [5, 17],
    adultAgeRange: [18, 80],
    link: "https://www.rula.com",
    description:
      "We’re building the future of mental health care; a comprehensive behavioral health solution that helps every person access high quality care from a licensed professional, at a time that works for them and at a cost they can afford.",
    TypeOfService: ["therapy"],
    url: "https://www.rula.com",
  },

  // {
  //   companyName: "Lifestance Health",
  //   logo: "lifestance_logo.png",
  //   borderColor: "#0067CE",
  //   availability: 3,
  //   virtualLocations: getAllStates(),
  //   inPersonLocations: getAllStates(),
  //   insurances: [
  //     "Blue Cross",
  //     "Anthem",
  //     "Optum",
  //     "United",
  //     "Aetna",
  //     "Cigna",
  //     "Humana",
  //     "and many others (they have lists of what insurances each office takes)",
  //   ],
  //   price: "Price Varies",
  //   priceDetail: "dependent on location and type of care",
  //   youthAgeRange: [0, 17],
  //   adultAgeRange: [18, 100],
  //   description: "Lifestance",
  //   TypeOfService: ["therapy"],

  //   url: "https://lifestance.com",
  // },
  // {
  //   companyName: "Brightline",
  //   logo: "brightline_logo.png",
  //   availability: 4,
  //   virtualLocations: getAllStates(),
  //   inPersonLocations: null,
  //   insurances: ["Premera", "Aetna", "Blue Cross Blue Shield", "and more"],
  //   price: "$220",
  //   priceDetail:
  //     "coaching - $70/session, therapy - $225/session, parent check-in w/therapist - $170/session, medication evaluation - $270, medication support - $190/session",
  //   youthAgeRange: [0, 17],
  //   adultAgeRange: null,
  //   description:
  //     "Brightline specializes in mental health services for children, teens, and families. They offer personalized coaching, therapy, and medication support, focusing on accessibility and convenience.",
  //   TypeOfService: ["therapy", "psychiatry"],
  //   url: "https://www.hellobrightline.com",
  // },
  {
    companyName: "Talkiatry",
    logo: "talkiatry_logo.png",
    borderColor: "#FFCC33",
    availability: 7,
    virtualLocations: getAllStatesExcluding([
      "Idaho",
      "New Mexico",
      "North Dakota",
      "South Dakota",
      "Wyoming",
    ]),
    inPersonLocations: null,
    insurances: [
      "Aetna",
      "Banner",
      "Beacon",
      "Bind",
      "Blue Cross Blue Shield",
      "Centivo",
      "Cigna",
      "ComPsych",
      "Emblem Health",
      "Geisinger",
      "Humana",
      "Kaiser",
      "MVP Health Care",
      "Magellan",
      "Magmacare",
      "Medicare",
      "NYSHIP",
      "Northwell Health",
      "Optum",
      "Oscar",
      "Point Comfort",
      "Provider Network of America",
      "Quest Behavioral Health",
      "Sana Benefits",
      "Tufts Health Plan",
      "UPMC",
      "United Healthcare",
      "VNSNY Choice",
      "Western Health Advantage",
    ],
    checkInsurancePage: "https://www.talkiatry.com/insurance",
    price: "n/a",
    priceDetail: "no self pay",
    selfPay: false,
    youthAgeRange: [5, 17],
    adultAgeRange: [18, 80],
    description:
      "Talkiatry provides comprehensive psychiatric care through a combination of virtual visits and a network of in-person providers. They accept a broad range of insurance plans and focus on quality mental health care.",
    TypeOfService: ["therapy"],
    url: "https://www.talkiatry.com",
  },
  // {
  //   companyName: "Array Behavioral Health",
  //   logo: "arraybc_logo.png",
  //   availability: 6,
  //   virtualLocations: getAllStates(),
  //   insurances: ["list of insurances by state"],
  //   price: "$150",
  //   priceDetail:
  //     "psych eval - $275 for 30 min session, follow-up - $150/15 mins, $350/30 mins",
  //   youthAgeRange: [5, 17],
  //   adultAgeRange: [18, 80],
  //   description:
  //     "Array Behavioral Health offers a range of mental health services, including psychiatric evaluations and follow-ups. They provide virtual care across multiple states and focus on accessibility and convenience.",
  //   TypeOfService: ["therapy", "psychiatry"],

  //   url: "https://arraybc.com",
  // },
  {
    companyName: "InStride",
    logo: "instride_logo.png",
    borderColor: "#145E7C",
    virtualLocations: [
      "Connecticut",
      "Massachusetts",
      "New Hampshire",
      "New Jersey",
      "New York",
      "Pennsylvania",
      "Rhode Island",
      "Maine",
    ],
    insurances: [
      "Mass General Brigham Health Plan",
      "Anthem BCBS (including Empire)",
      "Blue Cross Blue Shield of Massachusetts",
      "Blue Cross Blue Shield of Rhode Island",
      "ConnectiCare",
      "EmblemHealth",
      "Group Health Incorporated (GHI)",
      "Harvard Pilgrim",
      "Horizon Blue Cross Blue Shield of New Jersey",
      "NYSHIP",
      "Optum",
      "Oscar",
      "Oxford",
      "UnitedHealthcare",
      "Wellpoint (UniCare)",
    ],
    price: "n/a",
    priceDetail: "no self pay",
    youthAgeRange: [7, 17],
    adultAgeRange: [18, 22],
    specialties: ["OCD", "Anxiety"],
    description:
      "InStride provides specialized mental health services focusing on OCD and Anxiety, serving several northeastern states. They work with a wide range of insurance providers and focus on delivering tailored care.",
    TypeOfService: ["therapy", "psychiatry"],

    url: "https://instride.health",
  },
  {
    companyName: "Brightside Health",
    logo: "brightside_logo.png",
    borderColor: "#0055b3",
    availability: 3,
    virtualLocations: getAllStates(),
    inPersonLocations: getAllStates(),
    insurances: [
      "Blue Cross",
      "Anthem",
      "Optum",
      "United",
      "Aetna",
      "Cigna",
      "Humana",
      "and many others (they have lists of what insurances each office takes)",
    ],
    price: "Price Varies",
    priceDetail: "dependent on location and type of care",
    youthAgeRange: [0, 17],
    adultAgeRange: [18, 100],
    description: "Brightside is amazing",
    TypeOfService: ["therapy, psychiatry"],

    url: "https://www.brightside.com",
  },
  {
    companyName: "Hopscotch",
    logo: "hopscotch_logo.png",
    borderColor: "#3366CC",
    availability: 4,
    virtualLocations: [
      "Alabama",
      "California",
      "Delaware",
      "Florida",
      "Georgia",
      "New Jersey",
      "New York",
      "North Carolina",
      "Ohio",
      "Pennsylvania",
      "Texas",
      "West Virginia",
    ],
    inPersonLocations: null,
    insurances: [
      "Aetna",
      "United Healthcare",
      "Optum",
      "AmeriHealth NJ",
      "Blue Cross Blue Shield",
      "ComPsych",
      "Cigna",
    ],
    checkInsurancePage:
      "https://app.joinhopscotch.com/search-directory/check-my-insurance?",
    price: "$130",
    priceDetail:
      "intake - $150, 30 min - $85, 45 min - $130, 60 min - $140, family therapy - $130",
    youthAgeRange: [5, 17],
    adultAgeRange: null,
    description:
      "Hopscotch connects patients with licensed therapists for virtual and in-person mental health care. They accept various insurances and focus on providing accessible mental health services for all ages.",
    TypeOfService: ["therapy"],

    url: "https://www.joinhopscotch.com",
  },
  {
    companyName: "Backpack Health",
    logo: "backpack_logo.png",
    borderColor: "#401279",
    availability: 3,
    virtualLocations: ["Florida", "Georgia", "Illinois", "Ohio", "Texas"],
    insurances: [
      "Amerigroup",
      "Aetna",
      "CareFirtst",
      "Cigna",
      "Evernorth",
      "Medicaid",
      "United Healthcare",
    ],
    // self pay prices and insurance prices
    price: "$96",
    insurancePrice: "0",
    priceDetail:
      "Self Pay Rates: Individual sessions for children (5-12) and adults (18+) are $96 per session; group therapy for children (5-12) and teens (13-17) are $55 per session; group therapy for parents are $47 per session; family sessions are $150 per session",
    youthAgeRange: [5, 17],
    adultAgeRange: [18, 70],
    description:
      "Backpack Health offers a digital health app that helps individuals manage and share their health information, focusing on making it easy to carry and communicate vital details.",
    TypeOfService: ["appBased", "family", "group", "therapy"],
    url: "https://hellobackpack.com",
  },
  {
    companyName: "ThriveWorks",
    logo: "thriveworks_logo.png",
    borderColor: "#ffcc40",
    availability: 3,
    virtualLocations: getAllStates(),
    inPersonLocations: getAllStates(),
    insurances: [
      "Blue Cross",
      "Anthem",
      "Optum",
      "United",
      "Aetna",
      "Cigna",
      "Humana",
      "and many others (they have lists of what insurances each office takes)",
    ],
    price: "Price Varies",
    priceDetail: "dependent on location and type of care",
    youthAgeRange: [0, 17],
    adultAgeRange: [18, 100],
    description:
      "ThriveWorks offers premium mental health services with nationwide availability, providing both in-person and virtual care. They work with a wide range of insurances and offer flexible appointment times.",
    TypeOfService: ["therapy"],

    url: "https://thriveworks.com",
  },
  {
    companyName: "Brave Health",
    logo: "brave_logo.png",
    borderColor: "#19194F",
    availability: 3,
    virtualLocations: ["Florida", "Georgia", "Illinois", "Ohio", "Texas"],
    insurances: [
      "We accept more than 200 insurance plans, including Medicaid, Medicare, and Commercial plans",
      "We are in-network with Aetna",
      "Ambetter",
      "Amerigroup",
      "AmeriHealth Caritas Florida (ACFL)",
      "Blue Cross Blue Shield (BCBS)",
      "Centene",
      "Cigna",
      "Fidelis",
      "HealthFirst",
      "Humana",
      "Molina",
      "Simply",
      "Sunshine",
      "Superior/United/Optum",
      "Wellcare",
      "and many more",
    ],
    price: "$300",
    priceDetail:
      "consultation - $390, medication management appointment - $258, medication management support - $182, counseling - $182 (30 min - $150) (45 min - $225) (1 hr - $300), family counseling - $170, group therapy - $82.50",
    youthAgeRange: [13, 17],
    adultAgeRange: [18, 60],
    description:
      "Brave Health provides a wide range of mental health services, including medication management and counseling. They accept over 200 insurance plans, including Medicaid and Medicare, and focus on delivering comprehensive care.",
    TypeOfService: ["therapy", "psychiatry"],
    url: "https://bebravehealth.com",
  },
  {
    companyName: "Positive Development",
    logo: "positivedevelopment_logo.png",
    borderColor: "#2A2B6B",
    availability: 12,
    virtualLocations: null,
    inPersonLocations: [
      "California",
      "District of Columbia",
      "Florida",
      "Illinois",
      "Maryland",
      "New Jersey",
      "Oklahoma",
      "Virginia",
    ],
    insurances: ["varies by location (more info in FAQ)"],
    price: "Price varies by location",
    priceDetail: "varies by location",
    youthAgeRange: [5, 18],
    adultAgeRange: null,
    description:
      "Positive Development provides personalized mental health care with a focus on in-person services across several states. They cater primarily to children and adolescents, offering services tailored to developmental needs.",
    url: "https://www.positivedevelopment.com",
  },
  {
    companyName: "Wave Life",
    logo: "wave_logo.png",
    borderColor: "#FF9A78",
    availability: 0,
    virtualLocations: ["California", "nationwide (virtual)"],
    insurances: ["Aetna in CA"],
    price: "Free",
    priceDetail: "app is free, $25/session with coach on app",
    youthAgeRange: [13, 17],
    adultAgeRange: [18, 80],
    description:
      "Wave Life offers a mobile app that provides mental health support and coaching. They focus on delivering mental health resources at an affordable rate, with services available both in California and nationwide virtually.",
    TypeOfService: ["appBased", "coaching"],
    url: "https://www.wavelife.io",
  },
  // {
  //   companyName: "Joon Care",
  //   logo: "joon_logo.png",
  //   borderColor: "#0C754E",
  //   availability: 3,
  //   virtualLocations: [
  //     "California",
  //     "Delaware",
  //     "Oregon",
  //     "Pennsylvania",
  //     "Texas",
  //     "New York",
  //     "Washington",
  //   ],
  //   insurances: [
  //     "AmeriHealth Pennsylvania",
  //     "Blue Cross and Blue Shield of Texas",
  //     "Carelon",
  //     "ComPsych",
  //     "Highmark Blue Cross Blue Shield (BCBS) Pennsylvania",
  //     "Highmark Blue Cross Blue Shield (BCBS) Delaware",
  //     "Independence Blue Cross",
  //     "Kaiser Permanente Washington",
  //     "Magellan",
  //   ],
  //   price: "$150",
  //   priceDetail:
  //     "Joon charges $150 for your initial 50-minute session. If the client is a minor, this call will include the client's parents/guardians as well. After this session, your Joon therapist will send you a customized treatment plan that will detail ongoing expenses. A typical session costs $125 per session billed every 4 weeks. This includes weekly live video-therapy sessions, access to app-based skills, and a monthly parent check-in.",
  //   youthAgeRange: [13, 17],
  //   adultAgeRange: [18, 26],
  //   description:
  //     "Joon Care provides virtual therapy for teens and young adults, focusing on building resilience and managing mental health challenges. Their approach combines technology with personalized care.",
  //   TypeOfService: ["therapy", "appBased"],
  //   url: "https://www.joon.com",
  // },
  {
    companyName: "Sanarai",
    logo: "sanarai_logo.png",
    borderColor: "#7348A1",
    availability: 8,
    virtualLocations: [
      "Alabama",
      "California",
      "Delaware",
      "Florida",
      "Georgia",
      "New Jersey",
      "New York",
      "North Carolina",
      "Ohio",
      "Pennsylvania",
      "Texas",
      "West Virginia",
    ],
    insurances: ["N/A"],
    price: "$49",
    priceDetail: "$49 - session, $20 - consultation",
    youthAgeRange: [5, 17],
    adultAgeRange: [18, 80],
    url: "https://www.sanarai.com",
  },
  {
    companyName: "Vita Health",
    logo: "vitahealth_logo.png",
    borderColor: "#853A7D",
    availability: 3,
    virtualLocations: ["Florida", "Georgia", "Illinois", "Ohio", "Texas"],
    insurances: [
      "We accept more than 200 insurance plans, including Medicaid, Medicare, and Commercial plans",
      "We are in-network with Aetna",
      "Ambetter",
      "Amerigroup",
      "AmeriHealth Caritas Florida (ACFL)",
      "Blue Cross Blue Shield (BCBS)",
      "Centene",
      "Cigna",
      "Fidelis",
      "HealthFirst",
      "Humana",
      "Molina",
      "Simply",
      "Sunshine",
      "Superior/United/Optum",
      "Wellcare",
      "and many more",
    ],
    price: "$300",
    priceDetail: "",
    youthAgeRange: [5, 17],
    adultAgeRange: null,
    description:
      "Brave Health provides a wide range of mental health services, including medication management and counseling. They accept over 200 insurance plans, including Medicaid and Medicare, and focus on delivering comprehensive care.",
    TypeOfService: ["therapy", "psychiatry"],
    url: "https://bebravehealth.com",
  },
  {
    companyName: "Equip Health",
    logo: "equip_logo.png",
    borderColor: "#007F79",
    availability: 3,
    virtualLocations: ["Florida", "Georgia", "Illinois", "Ohio", "Texas"],
    insurances: [
      "We accept more than 200 insurance plans, including Medicaid, Medicare, and Commercial plans",
      "We are in-network with Aetna",
      "Ambetter",
      "Amerigroup",
      "AmeriHealth Caritas Florida (ACFL)",
      "Blue Cross Blue Shield (BCBS)",
      "Centene",
      "Cigna",
      "Fidelis",
      "HealthFirst",
      "Humana",
      "Molina",
      "Simply",
      "Sunshine",
      "Superior/United/Optum",
      "Wellcare",
      "and many more",
    ],
    price: "$300",
    priceDetail: "",
    youthAgeRange: [5, 17],
    adultAgeRange: null,
    description:
      "Brave Health provides a wide range of mental health services, including medication management and counseling. They accept over 200 insurance plans, including Medicaid and Medicare, and focus on delivering comprehensive care.",
    TypeOfService: ["therapy", "psychiatry"],
    url: "https://www.equip.health",
  },
  {
    companyName: "Mightier",
    logo: "mightier_logo.png",
    borderColor: "#0078BE",
    availability: 0,
    virtualLocations: getAllStates(),
    inPersonLocations: getAllStates(),
    insurances: [""],
    price: "$336",
    priceDetail:
      "Mightier is available for as low as $28/month (for 12 months - $336), $34/month (for 6 months - $204), or $40/month (monthly). Ask for the free trial!",
    youthAgeRange: [6, 14],
    adultAgeRange: null,
    description:
      "Mightier is an evidence-based (by Harvard and Boston Children's) sensor and table program designed for children with emotional regulation challenges, using biofeedback games to teach coping skills. This program supports children in building emotional strength.",
    TypeOfService: ["gameBased", "appBased"],
    url: "https://www.mightier.com",
  },
  {
    companyName: "HeroJourneyClub",
    logo: "herojourneyclub_logo.png",
    borderColor: "#4739e5",
    availability: 0,
    virtualLocations: getAllStates(),
    inPersonLocations: getAllStates(),
    insurances: [""],
    price: "$336",
    priceDetail: "Hero Journey Club!",
    youthAgeRange: [6, 14],
    adultAgeRange: null,
    description:
      "Hero Journey Club is a mental health app that uses a game-based approach to help children and teens build emotional resilience and coping skills. The app is designed to help users navigate life's challenges and build confidence.",
    TypeOfService: ["gameBased", "appBased"],
    url: "https://www.herojourney.club",
  },
  // {
  //   companyName: "Foresight",
  //   logo: "foresight_logo.png",
  //   borderColor: "#163915",
  //   availability: 3,
  //   virtualLocations: [
  //     "Arizona",
  //     "California",
  //     "Colorado",
  //     "Florida",
  //     "Georgia",
  //     "Illinois",
  //     "Texas",
  //   ],
  //   inPersonLocations: [
  //     "Arizona",
  //     "California",
  //     "Colorado",
  //     "Florida",
  //     "Georgia",
  //     "Illinois",
  //     "Texas",
  //   ],
  //   insurances: [
  //     "Aetna",
  //     "Anthem",
  //     "Beacon",
  //     "Cigna",
  //     "Humana",
  //     "Kaiser Permanente",
  //     "Optum",
  //     "United Healthcare",
  //     "Medicare",
  //   ],
  //   price: "",
  //   priceDetail: "",
  //   youthAgeRange: [12, 17],
  //   adultAgeRange: [18, 65],
  //   description:
  //     "Foresight offers mental health services with a focus on precision psychiatry. They use data-driven approaches to personalize treatment plans for their clients.",
  //   TypeOfService: ["therapy", "psychiatry"],
  //   url: "https://www.foresightmentalhealth.com",
  // },
  {
    companyName: "Wysa",
    logo: "wysa_logo.png",
    borderColor: "#54A2AC",
    availability: 3,
    virtualLocations: getAllStates(),
    insurances: "All",
    price: "$0",
    priceDetail: "",
    youthAgeRange: [13, 17],
    adultAgeRange: [18, 60],
    description:
      "Wysa is the original AI-powered mental health support platform that offers chat-based therapy and self-care tools. It provides users with a safe space to talk and reflect. Recently, Wysa also launched coaching as well.",
    TypeOfService: ["appBased", "coaching", "textAIChat"],
    url: "https://www.wysa.com",
  },
  {
    companyName: "Answers Now",
    logo: "answersnow_logo.png",
    borderColor: "#A389D4",
    availability: 3,
    virtualLocations: ["Georgia", "Texas", "Virginia"],
    /*
    VIRGINIA
    ‍‍Commercial: Aetna, Anthem, Cigna/Evernorth, Optima
    ‍Medicaid: Magellan, Molina, Aetna Better Health, Anthem HealthKeepers, Optima Medicaid, Virginia Premier

    ‍GEORGIA
    ‍Commercial: Aetna, Cigna/Evernorth
    ‍Medicaid: Fee for service GA Medicaid, Caresource, Amerigroup, Peach State Health Plan

    ‍TEXAS
    Commercial: Aetna, Blue Cross and Blue Shield of Texas, Cigna
    ‍Medicaid: Dell Children’s Health Plan, Blue Cross and Blue Shield of Texas MCO, Wellpoint MCO (formerly Amerigroup), Magellan
    */
    insurances: [],
    price: "$300",
    priceDetail: "",
    youthAgeRange: [1, 17],
    adultAgeRange: [18, 21],
    description:
      "Answers Now provides virtual therapy for individuals with autism and their families, offering personalized, evidence-based support through a convenient app.",
    TypeOfService: ["autism", "ABA", "neurodiverse"],
    url: "https://www.answersnow.com",
  },
  {
    companyName: "Lief",
    logo: "lief_logo_new.png",
    availability: 0,
    virtualLocations: getAllStates(),
    insurances: [],
    price: "$69",
    priceDetail:
      "Lief's most popular plan, which offers a Lief device plus personalized coaching, costs $99/month. Lief also offers a $69/month plan without coaching.",
    youthAgeRange: [13, 17],
    adultAgeRange: [18, 70],
    description:
      "Lief offers wearable technology designed to improve mental health through biofeedback and heart rate variability monitoring, helping users manage stress in real-time. Lief’s HRV wearable helps reduce stress and anxiety in real time using personalized heart rate variability biofeedback training",
    TypeOfService: ["wearable", "biomarkers"],
    url: "https://www.getlief.com",
  },
  {
    companyName: "Blackbird Health",
    // logo: "brave_logo.png",
    availability: 8,
    inPersonLocations: ["Pennsylvania", "Virginia"],
    virtualLocations: ["DC Metro", "Pennsylvania", "Virginia"],
    insurances: [],
    price: "",
    priceDetail: "",
    youthAgeRange: [5, 17],
    adultAgeRange: null,
    description:
      "Blackbird Health provides personalized mental health care with a focus on technology-driven solutions, offering services that cater to both youths and adults.",
    TypeOfService: ["parent", "psychiatry", "therapy", "autism"],
    url: "https://blackbirdhealth.com",
  },
  {
    companyName: "Brightside Health",
    // logo: "brave_logo.png",
    availability: 2,
    virtualLocations: getAllStates(),
    insurances: "All",
    price: "",
    priceDetail: "",
    youthAgeRange: [13, 17],
    adultAgeRange: [18, 30],
    description:
      "Brightside Health is a mental health platform that offers personalized care plans for individuals seeking therapy or psychiatry services. They provide accessible care through virtual visits and a focus on quality mental health support.",
    TypeOfService: ["therapy", "psychiatry"],
    url: "https://www.brightside.com",
  },
  {
    companyName: "Equip",
    // logo: "brave_logo.png",
    availability: 21,
    virtualLocations: ["Florida", "Georgia", "Illinois", "Ohio", "Texas"],
    insurances: [],
    price: "",
    priceDetail: "",
    youthAgeRange: [6, 17],
    adultAgeRange: [18, 30],
    description:
      "Equip provides virtual treatment for eating disorders, offering comprehensive, family-based care to help individuals achieve lasting recovery.",
    TypeOfService: ["therapy", "family", "eating"],
    url: "https://www.equip.health",
  },
];

export default mentalHealthProviders;
