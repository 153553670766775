import { useEffect, useState } from "react";
import { getAnalytics, logEvent } from "firebase/analytics";

const BlueberryShortTallyPage = () => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    // Check if the device is mobile
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Run on initial load
    window.addEventListener("resize", handleResize); // Adjust on window resize

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Initialize Firebase Analytics
    const analytics = getAnalytics();

    // Get the current path dynamically
    const path = window.location.pathname;

    // Log page view for unified "/blueberry" tracking
    if (path === "/partners/blueberry" || path === "/partner/blueberry") {
      logEvent(analytics, "page_view", { path: "/blueberry" });
    }

    // Dynamically load the Tally widget script
    const script = document.createElement("script");
    script.src = "https://tally.so/widgets/embed.js";
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      // Load all Tally embeds on the page
      if (window.Tally) {
        window.Tally.loadEmbeds();
      }
    };

    return () => {
      // Cleanup: remove the script when the component is unmounted
      document.head.removeChild(script);
    };
  }, []); // Empty dependency array ensures this runs only once

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        margin: "0",
        marginTop: isMobile ? "0px" : "-100px", // Conditional margin based on device type
        overflow: "hidden",
        position: "relative",
      }}
    >
      <iframe
        data-tally-src="https://tally.so/r/w4NalX"
        width="100%"
        height="100%"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        title="SelfCare Navigation Form for Blueberry"
        style={{
          position: "absolute",
          top: "0",
          right: "0",
          bottom: "0",
          left: "0",
          border: "0",
        }}
      ></iframe>
    </div>
  );
};

export default BlueberryShortTallyPage;

// import { useEffect } from "react";
// import { getAnalytics, logEvent } from "firebase/analytics";

// const BlueberryShortTallyPage = () => {
//   const analytics = getAnalytics();
//   // Log both paths under a unified name
//   if (path === "/partners/blueberry" || path === "/partner/blueberry") {
//     logEvent(analytics, "page_view", { path: "/blueberry" }); // Unified path name
//   }
//   // logEvent(analytics, "page_view", { path: "/partners/blueberry" });

//   useEffect(() => {
//     // Dynamically load the Tally widget script
//     const script = document.createElement("script");
//     script.src = "https://tally.so/widgets/embed.js";
//     script.async = true;
//     document.head.appendChild(script);

//     script.onload = () => {
//       // Load all Tally embeds on the page
//       if (window.Tally) {
//         window.Tally.loadEmbeds();
//       }
//     };

//     return () => {
//       // Cleanup: remove the script when the component is unmounted
//       document.head.removeChild(script);
//     };
//   }, []);

//   return (
//     <div
//       style={{
//         width: "100%",
//         height: "100%",
//         margin: "0",
//         marginTop: "-100px",
//         overflow: "hidden",
//         position: "relative",
//       }}
//     >
//       <iframe
//         data-tally-src="https://tally.so/r/w4NalX"
//         width="100%"
//         height="100%"
//         frameBorder="0"
//         marginHeight="0"
//         marginWidth="0"
//         title="SelfCare Navigation Form for Blueberry"
//         style={{
//           position: "absolute",
//           top: "0",
//           right: "0",
//           bottom: "0",
//           left: "0",
//           border: "0",
//         }}
//       ></iframe>
//     </div>
//   );
// };
// export default BlueberryShortTallyPage;

//   useEffect(() => {
//     // Dynamically load the Tally widget script
//     const script = document.createElement("script");
//     script.src = "https://tally.so/widgets/embed.js";
//     script.async = true;
//     document.head.appendChild(script);

//     script.onload = () => {
//       // Load all Tally embeds on the page
//       if (window.Tally) {
//         window.Tally.loadEmbeds();
//       }
//     };

//     return () => {
//       // Cleanup: remove the script when the component is unmounted
//       document.head.removeChild(script);
//     };
//   }, []);

//   return (
//     <div
//       style={{
//         display: "flex",
//         justifyContent: "center",
//         width: "100%",
//         maxWidth: "1000px",
//         height: "auto",
//       }}
//     >
//       <iframe
//         data-tally-src="https://tally.so/embed/w2JV8b?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
//         loading="lazy"
//         width="100%"
//         height="1867"
//         frameBorder="0"
//         marginHeight="0"
//         marginWidth="0"
//         title="SelfCare Navigation Form for Blueberry"
//         style={{ border: "none" }}
//       ></iframe>
//     </div>
//   );
// };
